
export default class CaseParser{
    constructor(){
       this.items = [
        {
            id: 1,
            value: "snake",
            label: "enum.case_parser.snake.title",
            example: "enum.case_parser.snake.example"
        },
        {
            id: 2,
            value: "camel",
            label: "enum.case_parser.camel.title",
            example: "enum.case_parser.camel.example"
        }
       ] 
    }
}